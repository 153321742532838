<template>
    <div id="login-page">

        <!-- Page Decoration -->
        <div
            id="login-page__tiled-background"
            :style="{ backgroundImage: 'url(' + loginBackgroundPatternDefault + ')' }"
        />

        <main>
            <!-- Logo -->
            <img :src="hickoryLogo">

            <h4 id="login-greeting">
                Hello, please login.
            </h4>

            <!-- Username Input -->
            <v-text-field
                id="username"
                v-model="username"
                data-cy="username"
                placeholder="Username"
                @keydown.enter="loginUser()"
            />

            <!-- Password Input -->
            <v-text-field
                id="password"
                v-model="password"
                data-cy="password"
                placeholder="Password"
                type="password"
                @keydown.enter="loginUser()"
            />

            <!-- Login Button -->
            <v-btn
                id="button-login"
                color="primary"
                data-cy="login"
                @click="loginUser()"
            >
                Login
            </v-btn>

            <!-- Error Message -->
            <div v-if="errorMessage">
                {{ errorMessage }}
            </div>

        </main>
    </div>
</template>

<script>
// Images
import LoginBackgroundPattern from '@/assets/images/software_preserve_pattern.png';
import HickoryLogo from '@/assets/images/logo_hickory.png';

export default {
    name: 'Login',
    props: {
        /* Query param that is the 'next' page to redirect to upon successful login */
        next: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            errorMessage: '',
            username: '',
            password: '',

            // Images
            loginBackgroundPatternDefault: LoginBackgroundPattern,
            hickoryLogo: HickoryLogo,
        };
    },
    methods: {
        /** @param { String } errorMessage */
        setErrorMessage( errorMessage ) {
            this.errorMessage = errorMessage;
        },
        clearErrorMessage() {
            this.setErrorMessage( '' );
        },
        async loginUser() {
            this.clearErrorMessage();
            try {
                await this.$store.dispatch( 'User/login', {
                    username: this.username,
                    password: this.password,
                } );
                await this.$router.push( {
                    path: this.next || '/',
                } );
            } catch ( error ) {
                this.setErrorMessage( error.message );
            }
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables.scss";

#login-page {
    background-color: $very-light-gray;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-template-areas:
            "login-page__tiled-background main"
            "login-page__tiled-background main"
            "login-page__tiled-background main"
            "login-page__tiled-background main"
            "login-page__tiled-background main";

    &__tiled-background {
        background-repeat: repeat;
        grid-area: login-page__tiled-background;
        grid-column: 1 / span 3;
        background-color: var(--v-primary-base);
        clip-path: polygon(0 0, 55% 0, 100% 100%, 0 100%);
    }

    main {
        grid-area: main;
        grid-column: 2 / span 3;
        grid-row: 2 / span 3;
        box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
        background-color: white;
        align-self: center;
        justify-self: center;
        padding: 25px 70px;
        width: 600px;
        border-radius: 5px;
        z-index: 1;
        text-align: left;

        img {
            height: 50px;
            float: left;
            margin-left: -50px;
        }

        #login-greeting {
            text-align: left;
            padding-top: 5rem;
            padding-bottom: 1rem;
            font-size: 1.5rem;
        }

        #button-login {
            float: right;
            height: 46px;
            width: 100px;
            margin-top: 30px;
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
}
</style>
